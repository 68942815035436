import React, { useContext } from "react";
import { Button, Dropdown, message, Space, Tooltip } from "antd";
import { DownOutlined, LogoutOutlined } from "@ant-design/icons";

function removeCookie() {
    // the cookie was created using res.cookie and is named "token"
    // erase the cookie
    document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}

const items = [
    {
        key: "1",
        label: "Selecionar Filial",
    },
    {
        key: "2",
        label: "Sair",
    },
];

const handleMenuClick = (e) => {
    if (e.key === "1") {
        //erase the cookie
        sessionStorage.removeItem("CNPJ");
        sessionStorage.removeItem("COLEGIO");
        window.location.href = "/filial";
    }
    if (e.key === "2") {
        // erase the cookie (not session storage) token
        sessionStorage.removeItem("CNPJ");
        sessionStorage.removeItem("COLEGIO");
        removeCookie("token");
        window.location.href = "/login";
        message.success("Logout realizado com sucesso!");
    }
};

const menuProps = {
    items,
    onClick: handleMenuClick,
};

function EmpresaHeader({ colegio }) {
    return (
        <div className="pt-3 pb-3 pr-5 pl-5 gap-2 bg-slate-900 flex text-white items-center content-center">
            <p className="font-bold">Colégio:</p>
            {colegio && <p>{colegio}</p>}
            <div className="absolute right-7 rounded-md bg-gray-50 text-black font-bold">
                <Dropdown menu={menuProps}>
                    <Button>
                        <Space>
                            <LogoutOutlined />
                        </Space>
                    </Button>
                </Dropdown>
            </div>
        </div>
    );
}

export default EmpresaHeader;
