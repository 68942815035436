import React from "react";
import {
    Input,
    DatePicker,
    Button,
    message,
    Form,
    Checkbox,
    Upload,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import axios from "axios";
import moment from "moment";
import formatDate from "../../functions/formatDate";

function Lancamento() {
    const onFinish = async (values) => {
        try {
            if ("errorFields" in values) {
                throw new Error("Campos Inválidos!");
            }
            const postData = JSON.stringify({
                ID_Lan: values.ID_Lan,
                ID_Boleto: values.ID_Boleto,
                ID_Empresa: values.ID_Empresa,
                ID_ClienteFornecedor: values.ID_ClienteFornecedor,
                RA: values.RA,
                NumeroDocumento: values.NumeroDocumento,
                CodigoBarra: values.CodigoBarra,
                IPTE: values.IPTE,
                CnabNossoNumero: values.CnabNossoNumero,
                PagRec: values.PagRec,
                StatusBoleto: values.StatusBoleto,
                Historico: values.Historico,
                DataCriacao: formatDate(values.DataCriacao),
                DataVencimento: formatDate(values.DataVencimento),
                DataEmissao: formatDate(values.DataEmissao),
                DataBaixa: formatDate(values.DataBaixa),
                ValorOriginal: values.ValorOriginal,
                ValorBaixa: values.ValorBaixa,
                ValorDesconto: values.ValorDesconto,
                ValorLiquido: values.valorLiquido,
                ValorBaixa: values.ValorBaixa,
            });
            var response;
            try {
                response = await axios.post(
                    "http://localhost:5000/api/db/lancamento/add",
                    postData,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                );
            } catch (e) {
                console.log(e);
            }
        } catch (error) {
            console.log(error);
            message.error(error.message);
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log(errorInfo);
        message.error(errorInfo.errorFields[0].errors);
    };

    const [form] = Form.useForm();

    return (
        <div className="bg-yellow-300 flex justify-center items-center">
            <div className="w-96 flex flex-col m-5 gap-5 p-5 shadow rounded-md bg-white">
                <div className="flex gap-5">
                    <h1 className="text-xl font-bold text-center">
                        Cadastro de Lançamento
                    </h1>
                    <Upload
                        name="file"
                        accept=".txt, .csv"
                        beforeUpload={(file) => {
                            const reader = new FileReader();
                            reader.onload = (e) => {
                                console.log(e.target.result);
                            };
                            reader.readAsText(file);
                            return false;
                        }}
                    >
                        <Button icon={<UploadOutlined />}>CSV</Button>
                    </Upload>
                </div>
                <Form
                    name="lancamento"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    form={form}
                >
                    <Form.Item
                        name="CodLancamento"
                        rules={[
                            {
                                required: true,
                                message: "Digite o Código do Lançamento!",
                            },
                        ]}
                    >
                        <Input placeholder="Código do Lançamento" />
                    </Form.Item>

                    <Form.Item
                        name="IdEmpresa"
                        rules={[
                            {
                                required: true,
                                message: "Digite o ID da Empresa!",
                            },
                        ]}
                    >
                        <Input placeholder="ID da Empresa" />
                    </Form.Item>
                    <Form.Item
                        name="ID_ClienteFornecedor"
                        rules={[
                            {
                                required: true,
                                message: "Digite o ID do Cliente Fornecedor!",
                            },
                            {
                                max: 25,
                                message: "ID muito longo! (Máx. 45)",
                            },
                        ]}
                    >
                        <Input placeholder="ID do Cliente Fornecedor" />
                    </Form.Item>
                    <Form.Item
                        name="RA"
                        rules={[
                            {
                                max: 20,
                                message: "RA muito longo! (Máx. 20)",
                            },
                        ]}
                    >
                        <Input placeholder="RA" />
                    </Form.Item>
                    <Form.Item
                        name="NumeroDocumento"
                        rules={[
                            {
                                max: 40,
                                message: "Muito longo! (Máx. 40)",
                            },
                        ]}
                    >
                        <Input placeholder="Número do Documento" />
                    </Form.Item>
                    <Form.Item
                        name="CodigoBarra"
                        rules={[
                            {
                                max: 45,
                                message: "Muito longo! (Máx. 45)",
                            },
                        ]}
                    >
                        <Input placeholder="Código de Barras" />
                    </Form.Item>
                    <Form.Item
                        name="IPTE"
                        rules={[
                            {
                                max: 48,
                                message: "Muito longo! (Máx. 48)",
                            },
                        ]}
                    >
                        <Input placeholder="IPTE" />
                    </Form.Item>
                    <Form.Item
                        name="CnabNossoNumero"
                        rules={[
                            {
                                max: 20,
                                message: "Muito longo! (Máx. 20)",
                            },
                        ]}
                    >
                        <Input placeholder="CNAB" />
                    </Form.Item>
                    <Form.Item name="PagRec">
                        <Checkbox>Pagamento Recebido</Checkbox>
                    </Form.Item>
                    <Form.Item name="StatusLan">
                        <Checkbox>Status do Lançamento</Checkbox>
                    </Form.Item>
                    <Form.Item name="Historico">
                        <Input rows={4} placeholder="Histórico..." />
                    </Form.Item>
                    <Form.Item name="DataCriacao">
                        <DatePicker
                            placeholder="Data de Criação"
                            style={{ width: "100%" }}
                        />
                    </Form.Item>
                    <Form.Item name="DataVencimento">
                        <DatePicker
                            placeholder="Data de Vencimento"
                            style={{ width: "100%" }}
                        />
                    </Form.Item>
                    <Form.Item name="DataEmissao">
                        <DatePicker
                            placeholder="Data de Emissão"
                            style={{ width: "100%" }}
                        />
                    </Form.Item>
                    <Form.Item name="DataBiaxa">
                        <DatePicker
                            placeholder="Data de Baixa"
                            style={{ width: "100%" }}
                        />
                    </Form.Item>
                    <Form.Item
                        name="ValorOriginal"
                        rules={[
                            {
                                pattern: new RegExp(
                                    /^[0-9]+(?:[,.][0-9]{1,2})?$/
                                ),
                                message: "Formato Inválido!",
                            },
                        ]}
                    >
                        <Input
                            placeholder="Valor Original"
                            prefix="R$"
                            suffix="BRL"
                        />
                    </Form.Item>
                    <Form.Item
                        name="ValorBaixa"
                        rules={[
                            {
                                pattern: new RegExp(
                                    /^[0-9]+(?:[,.][0-9]{1,2})?$/
                                ),
                                message: "Formato Inválido!",
                            },
                        ]}
                    >
                        <Input
                            placeholder="Valor Baixa"
                            prefix="R$"
                            suffix="BRL"
                        />
                    </Form.Item>

                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="bg-blue-400"
                            style={{ width: "100%" }}
                        >
                            Enviar
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
}

export default Lancamento;
