import React from "react";
import { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import axios from "axios";
import { Switch, Layout, Menu } from "antd";
import {
    UserOutlined,
    UploadOutlined,
    SettingOutlined,
    HomeOutlined,
    BarChartOutlined,
} from "@ant-design/icons";
import Parametros from "./Parametros";
import UploadPlanilha from "./UploadPlanilha";
import Inicio from "./Inicio";
import Dashboard from "./Dashboard";
import EmpresaHeader from "../../components/EmpresaHeader";
import { Route, BrowserRouter } from "react-router-dom";

const { Header, Content, Footer, Sider } = Layout;

function getItem(label, key, icon, children) {
    return {
        key,
        icon,
        children,
        label,
    };
}

function Home() {
    const [cookies, removeCookie] = useCookies([]);
    useEffect(() => {
        const verifyCookie = async () => {
            if (!cookies.token) {
                window.location.href = "/login";
            }
            const { data } = await axios.post(
                "/auth",
                {},
                { withCredentials: true }
            );
            return data.success
                ? null
                : (removeCookie("token"), (window.location.href = "/login"));
        };
        verifyCookie();
    }, [cookies, removeCookie]);

    var [cnpj, setCnpj] = useState({});
    useEffect(() => {
        const cnpj = sessionStorage.getItem("CNPJ");
        if (!cnpj) {
            window.location.href = "/filial";
        } else {
            setCnpj(cnpj);
        }
    }, []);

    const [selectedMenuItem, setSelectedMenuItem] = useState("1");

    const contentSwitch = (key) => {
        switch (key) {
            case "1":
                return <Inicio />;
            case "2":
                return <Dashboard />;

            case "3":
                return <UploadPlanilha />;
            case "4":
                return <Parametros />;
        }
    };

    const items = [
        getItem("Home", "1", <HomeOutlined />),
        getItem("Dashboard", "2", <BarChartOutlined />),
        getItem("Enviar Arquivo", "3", <UploadOutlined />),
        getItem("Parâmetros", "4", <SettingOutlined />),
    ];

    const [collapsed, setCollapsed] = useState(false);

    return (
        <div>
            <EmpresaHeader colegio={sessionStorage.getItem("COLEGIO")} />
            <Layout
                style={{
                    minHeight: "calc(100vh-48px)",
                }}
            >
                <Sider
                    collapsible
                    collapsed={collapsed}
                    onCollapse={(value) => setCollapsed(value)}
                >
                    <div className="demo-logo-vertical" />
                    <Menu
                        theme="dark"
                        defaultSelectedKeys={["1"]}
                        mode="inline"
                        items={items}
                        onClick={(e) => {
                            console.log(e.key);
                            setSelectedMenuItem(e.key);
                        }}
                    />
                </Sider>
                <Content
                    style={{
                        minHeight: "calc(100vh-48px)",
                    }}
                >
                    {contentSwitch(selectedMenuItem)}
                </Content>
            </Layout>
        </div>
    );
}

export default Home;
