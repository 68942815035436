import React from "react";
import {
    Input,
    DatePicker,
    Button,
    message,
    Form,
    Checkbox,
    Upload,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import axios from "axios";
import moment from "moment";
import formatDate from "../../functions/formatDate";

function Empresa() {
    const onFinish = async (values) => {
        try {
            if ("errorFields" in values) {
                throw new Error("Campos Inválidos!");
            }
            const postData = JSON.stringify({
                CodEmpresa: values.CodEmpresa,
                Nome: values.Nome,
                NomeFantasia: values.Nome,
                CNPJ: values.CNPJ,
                InscricaoEstadual: values.InscricaoEstadual,
                InscricaoMunicipal: values.InscricaoMunicipal,
                Telefone: values.Telefone,
                Email: values.Email,
                Cep: values.Cep,
                Rua: values.Rua,
                Numero: values.Numero,
                Complemento: values.Complemento,
                Bairro: values.Bairro,
                Cidade: values.Cidade,
                Estado: values.Estado,
                Pais: values.Pais,
                Ativo: 1,
                ID_Imagem: values.ID_Imagem,
                ID_Empresa: values.ID_Empresa,
                CriadoPor: "API",
                CriadoAs: formatDate(values.CriadoAs),
                ModificadoPor: null,
                ModificadoAs: null,
            });
            var response;
            try {
                response = await axios.post(
                    "http://localhost:5000/api/db/empresa/add",
                    postData,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                );
            } catch (e) {
                console.log(e);
            }
        } catch (error) {
            console.log(error);
            message.error(error.message);
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log(errorInfo);
        message.error(errorInfo.errorFields[0].errors);
    };

    const [form] = Form.useForm();

    return (
        <div className="bg-green-300 flex justify-center items-center">
            <div className="w-96 flex flex-col m-5 gap-5 p-5 shadow rounded-md bg-white">
                <h1 className="text-xl font-bold text-center">
                    Cadastro de Empresa
                </h1>
                <Form
                    name="empresa"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    form={form}
                >
                    <Form.Item
                        name="CodEmpresa"
                        rules={[
                            {
                                required: true,
                                message: "Digite o Código da Empresa!",
                            },
                        ]}
                    >
                        <Input placeholder="Código da Empresa" />
                    </Form.Item>
                    <Form.Item
                        name="Nome"
                        rules={[
                            {
                                max: 100,
                                message: "Nome muito longo! (Máx. 100)",
                            },
                        ]}
                    >
                        <Input placeholder="Nome da Empresa" />
                    </Form.Item>
                    <Form.Item
                        name="NomeFantasia"
                        rules={[
                            {
                                max: 100,
                                message:
                                    "Nome Fantasia muito longo! (Máx. 100)",
                            },
                        ]}
                    >
                        <Input placeholder="Nome Fantasia" />
                    </Form.Item>

                    <Form.Item
                        name="CNPJ"
                        rules={[
                            {
                                pattern: new RegExp(
                                    /^(\d{2}[-.\s]?\d{3}[-.\s]?\d{3}[-.\s\/]?\d{4}[-.\s]?\d{2})$/
                                ),
                                message: "CPNJ Inválido!",
                            },
                        ]}
                    >
                        <Input placeholder="CNPJ" />
                    </Form.Item>
                    <Form.Item
                        name="InscricaoEstadual"
                        rules={[
                            {
                                max: 20,
                                message:
                                    "Inscrição Estadual muito longa! (Máx. 20)",
                            },
                        ]}
                    >
                        <Input placeholder="Inscrição Estadual" />
                    </Form.Item>
                    <Form.Item
                        name="InscricaoMunicipal"
                        rules={[
                            {
                                max: 20,
                                message:
                                    "Inscrição Municipal muito longa! (Máx. 20)",
                            },
                        ]}
                    >
                        <Input placeholder="Inscrição Municipal" />
                    </Form.Item>
                    <Form.Item
                        name="Telefone"
                        rules={[
                            {
                                pattern: new RegExp(
                                    /^\(?[0-9]{2}\)?[0-9]{4,5}\-?[0-9]{4}$/
                                ),
                                message: "Telefone Inválido!",
                            },
                        ]}
                    >
                        <Input placeholder="Telefone" />
                    </Form.Item>
                    <Form.Item
                        name="Email"
                        rules={[
                            {
                                type: "email",
                                message: "Email Inválido!",
                            },
                        ]}
                    >
                        <Input placeholder="Endereço de Email" />
                    </Form.Item>
                    <Form.Item
                        name="CEP"
                        rules={[
                            {
                                pattern: new RegExp(/^[0-9]{5}\-?[0-9]{3}$/),
                                message: "CEP Inválido!",
                            },
                        ]}
                    >
                        <Input placeholder="CEP" />
                    </Form.Item>
                    <Form.Item
                        name="Rua"
                        rules={[
                            {
                                max: 100,
                                message: "Nome da Rua muito longo! (Máx. 100)",
                            },
                        ]}
                    >
                        <Input placeholder="Rua" />
                    </Form.Item>
                    <Form.Item
                        name="Numero"
                        rules={[
                            {
                                pattern: new RegExp(/^[0-9]{1,8}$/),
                                message: "Número Inválido",
                            },
                        ]}
                        className="w-[48%] float-left"
                    >
                        <Input placeholder="Número" />
                    </Form.Item>
                    <Form.Item
                        name="Complemento"
                        rules={[
                            {
                                max: 50,
                                message: "Muito longo! (Máx. 50)",
                            },
                        ]}
                        className="w-[48%] float-right"
                    >
                        <Input placeholder="Complemento" />
                    </Form.Item>
                    <Form.Item
                        name="Bairro"
                        rules={[
                            {
                                max: 80,
                                message: "Bairro muito longo! (Máx. 80)",
                            },
                        ]}
                        className="float-left w-[100%]"
                    >
                        <Input placeholder="Bairro" />
                    </Form.Item>
                    <Form.Item
                        name="Cidade"
                        rules={[
                            {
                                max: 32,
                                message: "Cidade muito longa! (Máx. 32)",
                            },
                        ]}
                        className="w-[48%] float-left"
                    >
                        <Input placeholder="Cidade" />
                    </Form.Item>
                    <Form.Item
                        name="Estado"
                        rules={[
                            {
                                pattern: new RegExp(/^[A-Za-z]{2}$/),
                                message: "Formato: SP, RJ, MS",
                            },
                        ]}
                        className="w-[48%] float-right  "
                    >
                        <Input placeholder="UF" />
                    </Form.Item>
                    <Form.Item
                        name="Pais"
                        rules={[
                            {
                                max: 20,
                                message: "País muito longo! (Máx. 20)",
                            },
                        ]}
                        className="float-left w-[100%]"
                    >
                        <Input placeholder="Pais" />
                    </Form.Item>
                    <Form.Item
                        name="ID_Empresa"
                        rules={[
                            {
                                pattern: new RegExp(/^[0-9]+$/),
                                message: "ID Inválido!",
                            },
                        ]}
                        className="float-left w-[100%]"
                    >
                        <Input placeholder="Empresa principal (ID)" />
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
}
export default Empresa;
