import { message } from "antd";
import axios from "axios";
import { useState, useEffect } from "react";
import { List } from "antd";
import { useCookies } from "react-cookie";
import Loader from "../../components/Loader";

function Filial() {
    const selectFilial = (cnpj, colegio) => {
        sessionStorage.setItem("CNPJ", cnpj);
        sessionStorage.setItem("COLEGIO", colegio);
        window.location.href = "/home";
    };

    useEffect(() => {
        const cnpj = sessionStorage.getItem("CNPJ");
        const colegio = sessionStorage.getItem("COLEGIO");
        if (cnpj && colegio) {
            window.location.href = "/home";
        }
    }, []);

    const [cookies, removeCookie] = useCookies([]);
    useEffect(() => {
        const verifyCookie = async () => {
            if (!cookies.token) {
                window.location.href = "/login";
            }
            const { data } = await axios.post(
                "/auth",
                {},
                { withCredentials: true }
            );
            return data.success
                ? null
                : (removeCookie("token"), (window.location.href = "/login"));
        };
        verifyCookie();
    }, [cookies, removeCookie]);

    var [filiais, setFiliais] = useState({});
    useEffect(() => {
        const getFiliais = async () => {
            const data = await axios.post(
                "/auth/filiais",
                {},
                { withCredentials: true }
            );
            if (data.data.data.data) {
                setFiliais(data.data.data.data);
            } else {
                setFiliais([]);
            }
        };
        getFiliais();
    }, []);

    return (
        <div className="flex justify-center items-center h-screen bg-blue-300">
            {filiais.length === undefined ? (
                <Loader />
            ) : (
                <div className="w-96 flex flex-col gap-5 p-5 shadow rounded-md bg-white">
                    <h1 className="text-xl font-bold text-center">
                        Selecione a Filial
                    </h1>
                    {filiais.length > 0 ? (
                        <List
                            itemLayout="horizontal"
                            dataSource={filiais}
                            renderItem={(item) => (
                                <List.Item
                                    onClick={() =>
                                        selectFilial(
                                            item.CNPJ,
                                            item.Instituição
                                        )
                                    }
                                    className="cursor-pointer hover:bg-gray-100 active:bg-gray-200"
                                >
                                    <List.Item.Meta
                                        title={item.Instituição}
                                        description={`CNPJ: ${item.CNPJ}`}
                                        className="ml-2"
                                    />
                                </List.Item>
                            )}
                        />
                    ) : (
                        <p className="text-red-600">
                            Nenhuma Filial Cadastrada!
                        </p>
                    )}
                </div>
            )}
        </div>
    );
}

export default Filial;
