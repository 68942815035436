import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Input, DatePicker, Button, message, Form, Checkbox } from "antd";
import axios from "axios";
import moment from "moment";
import { useCookies } from "react-cookie";

function Login() {
    const [cookies, removeCookie] = useCookies([]);
    //if logged in, redirect to /filial
    useEffect(() => {
        const verifyCookie = async () => {
            if (cookies.token) {
                console.log("No cookies");
                const { data } = await axios.post(
                    "/auth",
                    {},
                    { withCredentials: true }
                );
                return data.success ? (window.location.href = "/filial") : null;
            }
        };
        verifyCookie();
    }, [cookies, removeCookie]);

    const onFinish = async (values) => {
        console.log(values);
        try {
            const response = await axios.post("/auth/login", {
                codusuario: values.CodUsuario,
                senha: values.Senha,
            });
            console.log(response.data);
            if (response.data.success) {
                window.location.href = "/filial";
                message.success("Login realizado com sucesso!");
            } else {
                message.error("Erro ao realizar login!");
            }
        } catch (error) {
            message.error("Erro ao realizar login!");
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log(errorInfo);
        message.error(errorInfo.errorFields[0].errors);
    };

    const [expiracaoDisabled, setExpiracaoDisabled] = useState(true);

    const [form] = Form.useForm();

    return (
        <div className="flex justify-center items-center h-screen bg-blue-600">
            <div className="w-96 flex flex-col gap-5 p-5 shadow rounded-md bg-white">
                <h1 className="text-xl font-bold text-center">Login</h1>

                <Form
                    name="login"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    form={form}
                >
                    <Form.Item
                        name="CodUsuario"
                        rules={[
                            {
                                required: true,
                                message: "Digite o Usuário de Indentificação!",
                            },
                            {
                                max: 20,
                                message: "Usuário muito longo! (Máx. 20)",
                            },
                        ]}
                    >
                        <Input placeholder="Usuário" />
                    </Form.Item>
                    <Form.Item
                        name="Senha"
                        rules={[
                            { required: true, message: "Digite a senha!" },
                            { max: 1000, message: "Senha muito longa!" },
                        ]}
                    >
                        <Input.Password placeholder="Senha" />
                    </Form.Item>
                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="bg-blue-400"
                            style={{ width: "100%" }}
                        >
                            Entrar
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
}

export default Login;
