import React from "react";
import { Tabs } from "antd";
import Lancamento from "./lancamento";
import Empresa from "./empresa";
import ClienteFornecedor from "./clientefornecedor";

const items = [
    {
        key: "1",
        label: "Lançamentos",
        children: <Lancamento />,
    },
    {
        key: "2",
        label: "Empresas",
        children: <Empresa />,
    },
    {
        key: "3",
        label: "Clientes Fornecedores",
        children: <ClienteFornecedor />,
    },
];

function Cadastro() {
    return <Tabs defaultActiveKey="1" items={items} centered />;
}

export default Cadastro;
