import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale,
} from "chart.js";
import axios from "axios";

// Register the necessary components for Chart.js
ChartJS.register(
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale
);

const Dashboard = () => {
    const [selectedMonth, setSelectedMonth] = useState();
    const [selectedYear, setSelectedYear] = useState("2024");
    const [dataFromDB, setDataFromDB] = useState({});
    const [selectedStatus, setSelectedStatus] = useState("Enviado");

    const status = ["Enviado", "Entregue", "Aberto"];

    // Fetch data when the component mounts or when selectedYear changes
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.post(
                    "/api/db/historicomsg/data",
                    { CNPJ: sessionStorage.getItem("CNPJ") },
                    { withCredentials: true }
                );
                console.log("Fetched data:", response.data); // Log fetched data
                setDataFromDB(response.data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, [selectedYear]);

    // Extract years and months
    const years = Object.keys(dataFromDB);
    const months =
        selectedYear && dataFromDB[selectedYear]
            ? Object.keys(dataFromDB[selectedYear])
            : [];

    // Function to get data for the selected month and year
    const getDataPrimeiroGrafico = () => {
        if (
            !dataFromDB[selectedYear] ||
            !dataFromDB[selectedYear][selectedMonth]
        ) {
            return [0, 0, 0];
        }

        const monthData = dataFromDB[selectedYear][selectedMonth];

        return [
            monthData.Enviado || 0,
            monthData.Entregue || 0,
            monthData.Aberto || 0,
        ];
    };

    const getDataSegundoGrafico = () => {
        if (!dataFromDB[selectedYear]) {
            return [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        }

        const yearData = dataFromDB[selectedYear];
        console.log(yearData);

        return [
            yearData.Janeiro[selectedStatus] || 0,
            yearData.Fevereiro[selectedStatus] || 0,
            yearData.Março[selectedStatus] || 0,
            yearData.Abril[selectedStatus] || 0,
            yearData.Maio[selectedStatus] || 0,
            yearData.Junho[selectedStatus] || 0,
            yearData.Julho[selectedStatus] || 0,
            yearData.Agosto[selectedStatus] || 0,
            yearData.Setembro[selectedStatus] || 0,
            yearData.Outubro[selectedStatus] || 0,
            yearData.Novembro[selectedStatus] || 0,
            yearData.Dezembro[selectedStatus] || 0,
        ];
    };

    // Chart data
    const dataPrimeiroGrafico = {
        labels: ["Enviado", "Entregue", "Aberto"],
        datasets: [
            {
                label: `Emails ${selectedMonth}`,
                data: getDataPrimeiroGrafico(),
                backgroundColor: [
                    "rgba(75, 192, 192, 0.5)",
                    "rgba(153, 102, 255, 0.5)",
                    "rgba(255, 159, 64, 0.5)",
                ],
                borderColor: [
                    "rgba(75, 192, 192, 1)",
                    "rgba(153, 102, 255, 1)",
                    "rgba(255, 159, 64, 1)",
                ],
                borderWidth: 1,
            },
        ],
    };

    const dataSegundoGrafico = {
        labels: [
            "Janeiro",
            "Fevereiro",
            "Março",
            "Abril",
            "Maio",
            "Junho",
            "Julho",
            "Agosto",
            "Setembro",
            "Outubro",
            "Novembro",
            "Dezembro",
        ],
        datasets: [
            {
                label: `Emails ${selectedYear}`,
                data: getDataSegundoGrafico(),
                backgroundColor: [
                    "rgba(255, 0, 0, 0.5)",
                    "rgba(255, 69, 0, 0.5)",
                    "rgba(255, 165, 0, 0.5)",
                    "rgba(255, 255, 0, 0.5)",
                    "rgba(153, 255, 47, 0.5)",
                    "rgba(0, 255, 0, 0.5)",
                    "rgba(0, 255, 255, 0.5)",
                    "rgba(0, 191, 255, 0.5)",
                    "rgba(0, 0, 255, 0.5)",
                    "rgba(75, 0, 130, 0.5)",
                    "rgba(138, 43, 226, 0.5)",
                    "rgba(255, 0, 255, 0.5)",
                ],
                borderColor: [
                    "rgba(204, 0, 0, 1)",
                    "rgba(204, 55, 0, 1)",
                    "rgba(204, 132, 0, 1)",
                    "rgba(204, 204, 0, 1)",
                    "rgba(138, 204, 38, 1)",
                    "rgba(0, 204, 0, 1)",
                    "rgba(0, 204, 204, 1)",
                    "rgba(0, 153, 204, 1)",
                    "rgba(0, 0, 204, 1)",
                    "rgba(60, 0, 120, 1)",
                    "rgba(110, 35, 181, 1)",
                    "rgba(204, 0, 204, 1)",
                ],
                borderWidth: 1,
            },
        ],
    };

    // Chart options
    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        return ` ${context.raw}`;
                    },
                },
            },
        },
        scales: {
            y: {
                beginAtZero: true,
                min: 0,
                stepSize: 1,
                ticks: {
                    precision: 0,
                },
            },
        },
    };

    return (
        <div className="min-h-[calc(100vh-48px)] bg-gradient-to-r from-[#ef745c] to-[#34073d] p-8 flex flex-col items-center">
            <div className="flex items-center mb-8 justify-center bg-white p-4 rounded shadow-lg">
                <select
                    value={selectedYear}
                    onChange={(e) => {
                        setSelectedYear(e.target.value);
                    }}
                    className="mx-2 font-bold"
                >
                    <option value="">Selecione o Ano</option>
                    {years.map((year) => (
                        <option key={year} value={year}>
                            {year}
                        </option>
                    ))}
                </select>

                <select
                    value={selectedMonth}
                    onChange={(e) => setSelectedMonth(e.target.value)}
                    disabled={!selectedYear} // Disable if no year is selected
                    className="mx-2"
                >
                    <option value="">Selecione o Mês</option>
                    {months.map((month) => (
                        <option key={month} value={month}>
                            {month}
                        </option>
                    ))}
                </select>
            </div>

            {/* Layout for charts using Grid */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 w-full max-w-screen-xl">
                {/* Bar Chart 1 */}
                <div className="bg-gray-800 p-6 rounded-lg shadow-md flex flex-col items-center justify-center">
                    <h2 className="text-2xl font-semibold text-white mb-4">
                        {selectedMonth
                            ? "Emails - " + selectedMonth
                            : "Selecione o Mês!"}
                    </h2>
                    <div className="w-full h-80">
                        <Bar data={dataPrimeiroGrafico} options={options} />
                    </div>
                </div>

                {/* Bar Chart 2 */}
                <div className="bg-gray-800 p-6 rounded-lg shadow-md flex flex-col items-center justify-center">
                    <div className="flex items-center mb-8 justify-center bg-white p-1 rounded shadow-lg">
                        <select
                            value={selectedStatus}
                            onChange={(e) => setSelectedStatus(e.target.value)}
                            className="mx-2"
                        >
                            <option value="">Selecione o Status</option>
                            {status.map((status) => (
                                <option key={status} value={status}>
                                    {status}
                                </option>
                            ))}
                        </select>
                    </div>
                    <h2 className="text-2xl font-semibold text-white mb-4">
                        {selectedStatus
                            ? selectedStatus + " por mês"
                            : "Selecione o Status!"}
                    </h2>
                    <div className="w-full h-80">
                        <Bar data={dataSegundoGrafico} options={options} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
