import React from "react";
import { Upload, Button, message } from "antd";
import { UploadOutlined, DownloadOutlined } from "@ant-design/icons";
import axios from "axios";
// import PlanilhaModelo from "../assets/files/PlanilhaModelo.xlsx";

function UploadPlanilha() {
    const handleDownload = () => {
        const link = document.createElement("a");
        link.href = "/files/Planilha_modelo.xlsx";
        link.setAttribute("download", "PlanilhaModelo.xlsx");
        document.body.appendChild(link);
        link.click();
        link.remove();
    };

    const uploadfile = async (file) => {
        console.log("file upload");
        console.log(file);
        const cnpj = sessionStorage.getItem("CNPJ");
        const formdata = new FormData();
        formdata.append("file", file);
        console.log("Upload Planilha = " + cnpj);
        formdata.append("cnpj", cnpj);
        console.log(formdata);
        const response = await axios.post("/file/lancamentos", formdata);

        if (response.status === 200) {
            message.success("Dados subidos com sucesso!");
        } else if (response.status === 500) {
            message.error("Erro ao subir dados");
        } else {
            message.info("status = " + response.status);
        }

        console.log(response);
        return false;
    };

    return (
        <div className="min-h-[calc(100vh-48px)] flex items-center justify-center bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500">
            <div className="bg-white shadow-xl rounded-2xl p-8 max-w-md w-full">
                <h2 className="text-xl font-bold text-center text-gray-800 mb-8">
                    Upload & Download
                </h2>

                <div className="flex flex-col items-center space-y-6">
                    <Upload
                        name="file"
                        accept=".csv, .xlsx"
                        beforeUpload={uploadfile}
                    >
                        <Button
                            icon={<UploadOutlined />}
                            className={"bg-indigo-500 text-white"}
                        >
                            CSV / XLSX
                        </Button>
                    </Upload>
                    <Button
                        icon={<DownloadOutlined />}
                        onClick={handleDownload}
                    >
                        Download Modelo
                    </Button>
                </div>
            </div>
        </div>
    );

    // return (
    //     <div className="h-screen flex items-center justify-center gap-5 text-3xl font-semibold bg-slate-100">
    //         <Upload name="file" accept=".csv, .xlsx" beforeUpload={uploadfile}>
    //             <Button icon={<UploadOutlined />}>CSV / XLSX</Button>
    //         </Upload>
    //         <Button icon={<DownloadOutlined />} onClick={handleDownload}>
    //             Download Modelo
    //         </Button>
    //     </div>
    // );
}

export default UploadPlanilha;
