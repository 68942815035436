import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Signup from "./pages/auth/signup";
import Login from "./pages/auth/login";
import Cadastro from "./pages/cadastro";
import Filial from "./pages/auth/filial";
import Home from "./pages/home";
//() => {window.location.href = "/login";}
function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/signup" element={<Signup />} />
                <Route path="/login" element={<Login />} />
                <Route path="/cadastro" element={<Cadastro />} />
                <Route path="/filial" element={<Filial />} />
                <Route path="/home" element={<Home />} />
                <Route
                    exact
                    path="/"
                    element={
                        <div className="flex items-center justify-center min-h-screen bg-gradient-to-r from-blue-500 to-purple-600">
                            <div className="text-center p-8 bg-white bg-opacity-80 rounded-lg shadow-lg">
                                <h1 className="text-5xl font-bold mb-6 text-gray-800">
                                    Bem vind@ ao RobôEdu
                                </h1>
                                <p className="text-lg mb-8 text-gray-600">
                                    Por favor, realize o Login para continuar!
                                </p>
                                <button
                                    onClick={() => {
                                        window.location.href = "/login";
                                    }}
                                    className="bg-blue-600 text-white py-3 px-8 rounded-lg text-xl font-semibold shadow-lg hover:bg-blue-700 transition duration-300 transform hover:scale-105"
                                >
                                    Login
                                </button>
                            </div>
                        </div>
                    }
                />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
