import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Input, DatePicker, Button, message, Form, Checkbox } from "antd";
import axios from "axios";
import moment from "moment";

function Signup() {
    const onFinish = async (values) => {
        try {
            const dateFormat = "YYYY-MM-DD";
            if ("errorFields" in values) {
                throw new Error("Campos Inválidos!");
            }
            const postData = JSON.stringify({
                CodUsuario: values.CodUsuario,
                Nome: values.Nome,
                NomeSocial: values.NomeSocial,
                Email: values.Email,
                Status: "1",
                DataInicio: moment(values.DataInicio).format(dateFormat),
                DataExpiracao:
                    values.DataExpiracao == null
                        ? null
                        : moment(values.DataExpiracao).format(dateFormat),
                Senha: values.Senha,
                CriadoPor: "Webpage",
                ModificadoPor: null,
                ModificadoAs: null,
            });
            var response;
            try {
                response = await axios.post(
                    "http://localhost:5000/api/db/user/register",
                    postData,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                );
            } catch (e) {
                console.log(e);
            }
            const data = response.data;
        } catch (error) {
            console.log(error);
            message.error(error.message);
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log(errorInfo);
        message.error(errorInfo.errorFields[0].errors);
    };

    const [expiracaoDisabled, setExpiracaoDisabled] = useState(true);

    const [form] = Form.useForm();

    return (
        <div className="flex justify-center items-center h-screen bg-blue-300">
            <div className="w-96 flex flex-col gap-5 p-5 shadow rounded-md bg-white">
                <h1 className="text-xl font-bold text-center">
                    Cadastro de Usuário
                </h1>

                <Form
                    name="signup"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    form={form}
                >
                    <Form.Item
                        name="CodUsuario"
                        rules={[
                            {
                                required: true,
                                message: "Digite o Usuário de Indentificação!",
                            },
                            {
                                max: 20,
                                message: "Usuário muito longo! (Máx. 20)",
                            },
                        ]}
                    >
                        <Input placeholder="Usuário" />
                    </Form.Item>

                    <Form.Item
                        name="Nome"
                        rules={[
                            {
                                required: true,
                                message: "Digite o Nome!",
                            },
                            {
                                max: 45,
                                message: "Nome muito longo! (Máx. 45)",
                            },
                        ]}
                    >
                        <Input placeholder="Nome" />
                    </Form.Item>
                    <Form.Item
                        name="NomeSocial"
                        rules={[
                            {
                                required: true,
                                message: "Digite o Nome Social!",
                            },
                            {
                                max: 45,
                                message: "Nome Social muito longo! (Máx. 45)",
                            },
                        ]}
                    >
                        <Input placeholder="Nome Social" />
                    </Form.Item>
                    <Form.Item
                        name="Email"
                        rules={[
                            { required: true, message: "Digite o Email!" },
                            {
                                max: 60,
                                message: "Email muito longo! (Máx. 45)",
                            },
                            {
                                type: "email",
                                message: "Email inválido!",
                            },
                        ]}
                    >
                        <Input placeholder="Email" />
                    </Form.Item>
                    <Form.Item
                        name="DataInicio"
                        rules={[
                            {
                                required: true,
                                message: "Insira a Data de Início!",
                            },
                        ]}
                    >
                        <DatePicker
                            placeholder="Data de Inicio"
                            style={{ width: "100%" }}
                        />
                    </Form.Item>
                    <Checkbox
                        name="expira"
                        checked={expiracaoDisabled}
                        onChange={(e) => {
                            setExpiracaoDisabled(e.target.checked);
                            form.validateFields(["DataExpiracao"]);
                        }}
                    >
                        Não Expira
                    </Checkbox>
                    <Form.Item
                        name="DataExpiracao"
                        dependencies={["expira"]}
                        rules={[
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (
                                        value < getFieldValue("DataInicio") &&
                                        value != null
                                    ) {
                                        return Promise.reject(
                                            new Error(
                                                "A Data de Expiração deve ser posterior a Data de Início!"
                                            )
                                        );
                                    } else if (
                                        value == null &&
                                        expiracaoDisabled == false
                                    ) {
                                        return Promise.reject(
                                            new Error(
                                                "Insira a Data de Expiração!"
                                            )
                                        );
                                    }
                                    return Promise.resolve();
                                },
                            }),
                        ]}
                    >
                        <DatePicker
                            placeholder="Data de Expiração"
                            style={{ width: "100%" }}
                            disabled={expiracaoDisabled}
                        />
                    </Form.Item>

                    <Form.Item
                        name="Senha"
                        rules={[
                            { required: true, message: "Digite a senha!" },
                            { max: 1000, message: "Senha muito longa!" },
                        ]}
                    >
                        <Input.Password placeholder="Senha" />
                    </Form.Item>
                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="bg-blue-400"
                            style={{ width: "100%" }}
                        >
                            Enviar
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
}

export default Signup;
