import React from "react";
import { FloatButton } from "antd";
import { CommentOutlined, CustomerServiceOutlined } from "@ant-design/icons";

function Inicio() {
    //min-h-[calc(100vh-48px)]
    return (
        <div className="min-h-[calc(100vh-48px)] flex items-center justify-center bg-gradient-to-r from-[#293048] to-[#859398] text-white relative overflow-hidden">
            <div className="absolute inset-0" aria-hidden="true"></div>
            <div className="relative z-10 p-8 bg-black bg-opacity-70 rounded-2xl shadow-2xl text-center">
                <h1 className="text-4xl font-extrabold mb-6 leading-tight">
                    Seja bem-vind@ ao RobôEdu
                </h1>
                <p className="text-lg leading-relaxed">
                    Esta área ainda está em desenvolvimento.
                </p>
            </div>
        </div>
    );
}

export default Inicio;
