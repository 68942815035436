import React, { useEffect, useState } from "react";
import {
    Input,
    DatePicker,
    Button,
    message,
    Form,
    Checkbox,
    Upload,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import axios from "axios";
import moment from "moment";
import formatDate from "../../functions/formatDate";

function Parametros() {
    const [paramData, setParamData] = useState();
    useEffect(() => {
        const getParamData = async () => {
            const data = await axios.post(
                "/api/db/parametro/",
                { CNPJ: sessionStorage.getItem("CNPJ") },
                { withCredentials: true }
            );
            // console.log(data.data.data[0]);
            setParamData(data.data.data[0]);
        };
        getParamData();
    }, []);

    const onFinish = async (values) => {
        try {
            if ("errorFields" in values) {
                throw new Error("Campos Inválidos!");
            }
            const postData = JSON.stringify({
                ID: paramData.ID,
                NumDia: values.NumDia,
                // CodTemplate: values.CodTemplate,
                // Cabecalho: values.Cabecalho,
                // Corpo1: values.Corpo1,
                // Corpo2: values.Corpo2,
            });
            var response;
            try {
                response = await axios.post(
                    "/api/db/parametro/update/" + paramData.ID,
                    postData,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                );
                message.success("Parâmetros atualizados com sucesso!");
            } catch (e) {
                console.log(e);
            }
        } catch (error) {
            console.log(error);
            message.error(error.message);
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log(errorInfo);
        message.error(errorInfo.errorFields[0].errors);
    };

    const [form] = Form.useForm();

    return (
        <div className="min-h-[calc(100vh-48px)] flex items-center justify-center bg-gradient-to-r from-teal-400 to-indigo-500">
            <div className="bg-white shadow-xl rounded-2xl px-8 py-4 max-w-md h-full">
                <h1 className="text-xl font-bold text-center">
                    Editar Parâmetros
                </h1>
                <span>
                    Altere quantos dias antes do vencimento o responsável deve
                    ser notificado:
                </span>
                {paramData && (
                    <Form
                        name="parametro"
                        initialValues={paramData}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        form={form}
                    >
                        <Form.Item
                            className="mb-4"
                            name="NumDia"
                            rules={[
                                {
                                    required: true,
                                    message:
                                        "Digite o número de dias antes do vencimento para o lembrete!",
                                },
                            ]}
                        >
                            <Input placeholder="Número de dias" />
                        </Form.Item>

                        {/* 
                    <Form.Item
                        name="CodTemplate"
                        rules={[
                            {
                                required: true,
                                message: "Digite o Código do Template!",
                            },
                            {
                                max: 60,
                                message: "Código muito longo! (Máx. 60)",
                            },
                        ]}
                    >
                        <Input placeholder="Código do Template" />
                    </Form.Item>
                    <Form.Item
                        name="Cabecalho"
                        rules={[
                            {
                                required: true,
                                message: "Digite o Cabeçalho!",
                            },
                            {
                                max: 50,
                                message: "Cabeçalho muito longo! (Máx. 50)",
                            },
                        ]}
                    >
                        <Input placeholder="Cabeçalho" />
                    </Form.Item>
                    <Form.Item
                        name="Corpo1"
                        rules={[
                            {
                                max: 200,
                                message:
                                    "Primeiro corpo muito longo! (Máx. 200)",
                            },
                        ]}
                    >
                        <Input placeholder="Corpo do Email 1" />
                    </Form.Item>
                    <Form.Item
                        name="Corpo2"
                        rules={[
                            {
                                max: 200,
                                message:
                                    "Segundo corpo muito longo! (Máx. 200)",
                            },
                        ]}
                    >
                        <Input placeholder="Corpo do Email 2" />
                    </Form.Item>
                    <Form.Item
                        name="Rodape"
                        rules={[
                            {
                                max: 50,
                                message: "Rodapé muito longo! (Máx. 50)",
                            },
                        ]}
                    >
                        <Input placeholder="Rodapé" />
                    </Form.Item> */}
                        <Form.Item className="mb-4">
                            <Button
                                type="primary"
                                htmlType="submit"
                                className="bg-teal-500"
                                style={{ width: "100%" }}
                            >
                                Salvar
                            </Button>
                        </Form.Item>
                    </Form>
                )}
            </div>
        </div>
    );
}

export default Parametros;
